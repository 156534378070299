import axios from 'axios'
import router from '@/router'


// const useProxy = process.env.NODE_ENV !== 'production'

const service = axios.create({
    baseURL: "/jc-charging",
    timeout: 15000,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
    },
})

service.interceptors.request.use(config => {
    config.headers["X-Access-Token"] = localStorage.getItem("assToken");
    // config.headers.token = Vue.ls.get(ACCESS_TOKEN)
    return config
}, error => {
    Promise.reject(error)
})

service.interceptors.response.use(response => {
    if (response.data && response.data.code === 401) {
        console.log('401.重新登录')
        // clearLoginInfo();
        router.push('/login')
    }
    return response.data
}, err => {
    return Promise.resolve(err)
})

export function axiosServer (
    {
        url = '',
        type = 'GET',
        params = {},
    })
{
    if (typeof options === 'object') {
        console.log(params)
    }
    const config1 = {
        method: type,
        url: url,
        data: params,
    }
    if (type === 'GET') {
        config1.params = params
    }
    return service(config1)
}


